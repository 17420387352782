import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import TitleChecked from "../components/TitleChecked"
import { PageContainer } from "../components/PageContainer/styles";

const IndexPage = () => {

  return (<Layout>
    <SEO 
      title="Impresa Raf Service immagini e video"
      description="Impresa Raf Service è un'azienda giovane e dinamica che vanta un'esperienza ventennale nel campo dei servizi, dalla manutenzione ordinaria e straordinaria dei campi sintetici e in erba alla sanificazione ambientale di condomini, uffici, negozi e teatri"
    />
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12}>
            <TitleChecked title="RAF SERVICE si presenta in un videoclip" />
            <p><strong>RAF SERVICE</strong> è un'azienda giovane e dinamica che vanta un'esperienza ventennale nel campo dei servizi, dalla manutenzione ordinaria e straordinaria dei campi sintetici e in erba alla sanificazione ambientale di condomini, uffici, negozi e teatri.</p>
            <p style={{ marginBottom: '25px' }}><strong>eccellenza e professionalità al vostro servizio</strong>.</p>
            <div data-aos="flip-up" data-aos-duration="1400" data-aos-easing="ease-in">
              <iframe title="Raf Service videoclip" width="500" height="280" src="https://www.youtube.com/embed/5GbflGtm83Y" frameborder="0" allowfullscreen=""></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default IndexPage
